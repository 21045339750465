<script setup lang="ts">
import IconEye from '~~/assets/svg/icons/eye.svg';
import IconEyeSlash from '~~/assets/svg/icons/eye-slash.svg';

defineProps<{ modelValue?: string; inputAttrs?: Record<string, string> }>();

const emit = defineEmits<{
  (e: 'update:modelValue', v: string): void;
}>();
const show = ref(false);
</script>

<template>
  <div class="relative">
    <input
      class="form-control pr-54"
      v-bind="inputAttrs"
      :type="show ? 'text' : 'password'"
      :value="modelValue"
      @input="
        emit('update:modelValue', ($event.target as HTMLInputElement).value)
      "
    />
    <button
      type="button"
      class="btn-plain absolute top-1/2 right-16 -translate-y-1/2"
      :aria-label="show ? 'Hide password' : 'Show password'"
      @click="show = !show"
    >
      <IconEyeSlash v-if="show" />
      <IconEye v-else />
    </button>
  </div>
</template>
